// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-auction-jsx": () => import("./../../../src/pages/auction.jsx" /* webpackChunkName: "component---src-pages-auction-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-grants-jsx": () => import("./../../../src/pages/grants.jsx" /* webpackChunkName: "component---src-pages-grants-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-merkle-drop-jsx": () => import("./../../../src/pages/merkle-drop.jsx" /* webpackChunkName: "component---src-pages-merkle-drop-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-protocol-jsx": () => import("./../../../src/pages/protocol.jsx" /* webpackChunkName: "component---src-pages-protocol-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

